.App {
  z-index: 2;
  position: relative;
  text-align: justify;
  margin: 0 auto 0 auto;
  padding-left: 2vw;
  padding-right: 2vw;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

.AppHeader {
  height: 64px;
  position: sticky !important;
  z-index: 9999;
  top: 0;
  background-color: black;
  fill-opacity: 5%;
  min-height: 10vh;
  display: block;
  width: 100vw;
  text-align: center;
  padding-bottom: 0.5em;
  font-size: calc(10px + 2vmin);
  color: white;
}
.AppHeader a, .AppHeader a:visited, .AppHeader a:active {
  color: white;
  font-weight: bold;
}

.Tags {
  text-align: center;
  margin-top: 1em;
}

.StoryTag {
  display: inline-table;
  padding: .5em;
  margin-left: 1em;
  margin-right: 1em;
  margin-bottom: 1em;
  border-radius: 8px;
}

@media only screen and (max-width: 480px){
  .Story {
    width: 90vw;
  }
}
@media only screen and (min-width: 481px) and (max-width: 720px){
  .Story {
    width: 80vw;
  }
}
@media only screen and (min-width: 721px) and (max-width: 1200px){
  .Story {
    width: 70vw;
  }
}
@media only screen and (min-width: 1201px){
  .Story {
    width: 960px;
  }
}

.Story {
  margin: 1em auto;
  border-bottom: thin solid lightgray;
}
.Container {
  margin-top: 30px;
}

.StoryContent {
  border-bottom: thin solid lightgray;
}
h5 {
  text-align: center;
  margin-top: 15px;
}

.StoryTitle {
  font-size: 2em;
  text-align: center;
  background-color: black;
  border-radius: 8px;
}
.StoryImage {
  border-radius: 8px;
  max-width: -webkit-fill-available;
}
.pubDate {
  text-align: center;
  margin-top: 1em;
  margin-bottom: 1em;
  color: darkgray;
  font-size: small;
  font-style: oblique;
 }

a, a:visited, a:hover {
  text-decoration: none;
  color: dimgray;
}
.divTag {
  display: inline;
}
